import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import VideoCard from "../Atomics/VideoCard/VideoCard";
import Paginate from "../Atomics/Paginate/Paginate";
import YouTube from "react-youtube";

const ChannelVideos = ({ vdo }) => {
  const opts = {
    height: "180",
    width: "280",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  function _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    let duration = event.target.getDuration();
    console.log("duration :", (duration / 60).toFixed(0));
  }
  return (
    <>
      <div className="video-block section-padding ">
        <Row>
          <Col md={12}>
            <SectionHeader heading="Videos" />
          </Col>

          {vdo.map((v) => {
            return (
              <>
                <Col xl={3} sm={6} className="mb-3">
                  <VideoCard
                    imgSrc={v.snippet.thumbnails.default.url}
                    time="3:50"
                    videoTitle={v.snippet.title}
                    videoCategory="Education"
                    views="1.8M"
                    timeAgo="11 months"
                    verified
                    videoTitleHref={
                      "" + v.id.videoId
                    }
                  />
                </Col>
              </>
            );
          })}

          {/* <Col xl={3} sm={6} className="mb-3">https://youtube.com/watch?v=
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="/img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col> */}
        </Row>
      </div>
      <Paginate />
    </>
  );
};

export default ChannelVideos;
