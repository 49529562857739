import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import axios from "axios";
import ProjectApi from "../utils/ProjectApi";

export default function VideoUploadForm() {
	const [file,setFile] = useState();
	const [title,setTitle] = useState('');
	const [desc,setDesc] = useState('');
	const [keywords,setKeywords] = useState('');
	const [category,setCategory] = useState('');
	const [isUploading,setUploading] = useState(false);
	const user = JSON.parse(localStorage.getItem('user'))
	function handleSave()
	{
		let token = user.access_token;
		const pdata = new FormData();
		pdata.append('title',title);
		pdata.append('desc',desc);
		pdata.append('keywords',keywords);
		pdata.append('category',category);
		pdata.append('vdo',file);
		setUploading(true);

		ProjectApi.post('upload-video',pdata,{
            headers: {
                'content-type': 'multipart/form-data',
				Authorization: 'Bearer '+token
            }
        })
		.then((res)=>{
			// console.log(res.data);
			if(res.data.status == "success"){
				alert(res.data.msg);
			}
			else{
				alert(res.data.msg);
			}
			setUploading(false);
		})
		.catch((err)=>{
			console.log(err);
		})
	}
  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="osahan-form">
            <Row>
              <Col lg={12}>
                <Form.Group controlId="video-file">
                  <Form.Label>Video File</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="video-title">
                  <Form.Label>Video Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contrary to popular belief, Lorem Ipsum (2020) is not."
					value={title}
					onChange={(e)=>setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="video-description">
                  <Form.Label>About</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Description"
					value={desc}
					onChange={(e)=>setDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col lg={3}>
                <Form.Group controlId="video-orientation">
                  <Form.Label>Orientation</Form.Label>
                  <Form.Control as="select" className="custom-select" custom>
                    <option>Straight</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="video-privacy">
                  <Form.Label>Privacy Settings</Form.Label>
                  <Form.Control as="select" className="custom-select" custom>
                    <option>Public</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group controlId="video-monetization">
                  <Form.Label>Monetize</Form.Label>
                  <Form.Control as="select" className="custom-select" custom>
                    <option>Yes</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group controlId="video-license">
                  <Form.Label>License</Form.Label>
                  <Form.Control as="select" className="custom-select" custom>
                    <option>Standard</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
            </Row>

            <Row>
              <Col lg={5}>
                <Form.Group controlId="video-tags">
                  <Form.Label>Tags/keywords</Form.Label>
                  <Form.Control type="text" placeholder="Gaming, PS4" value={keywords}
					onChange={(e)=>setKeywords(e.target.value)} />
                </Form.Group>
              </Col>
			  <Col lg={5}>
                <Form.Group controlId="video-tags">
                  <Form.Label>Category</Form.Label>
                  <Form.Control type="text" placeholder="Gaming" value={category}
					onChange={(e)=>setCategory(e.target.value)} />
                </Form.Group>
              </Col>
              
            </Row>

            

            {/* <Row className="category-checkbox">
              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Abaft"
                  id="customCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Brick"
                  id="customCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Purpose"
                  id="customCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Shallow"
                  id="customCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Spray"
                  id="customCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Cemetery"
                  id="zcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Trouble"
                  id="zcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Pin"
                  id="zcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Fall"
                  id="zcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Leg"
                  id="zcustomCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Scissors"
                  id="czcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Stitch"
                  id="czcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Agonizing"
                  id="czcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Rescue"
                  id="czcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Quiet"
                  id="czcustomCheck5"
                />
              </Col>
              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Abaft"
                  id="customCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Brick"
                  id="customCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Purpose"
                  id="customCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Shallow"
                  id="customCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Spray"
                  id="customCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Cemetery"
                  id="zcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Trouble"
                  id="zcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Pin"
                  id="zcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Fall"
                  id="zcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Leg"
                  id="zcustomCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Scissors"
                  id="czcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Stitch"
                  id="czcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Agonizing"
                  id="czcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Rescue"
                  id="czcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Quiet"
                  id="czcustomCheck5"
                />
              </Col>
            </Row> */}
          </div>
          <div className="osahan-area text-center mt-3">
            {isUploading?<h6>uploading Please wait ...</h6> : <Button variant="outline-primary" onClick={()=>handleSave()}>Save Changes</Button>}
          </div>
        </Col>
      </Row>
      <Terms />
    </>
  );
}

function Terms() {
  return (
    <>
      <hr />
      <div className="terms text-center">
        <p className="mb-0">
          There are many variations of passages of Lorem Ipsum available, but
          the majority <a href="#">Terms of Service</a> and{" "}
          <a href="#">Community Guidelines</a>.
        </p>
        <p className="hidden-xs mb-0">
          Ipsum is therefore always free from repetition, injected humour, or
          non
        </p>
      </div>
    </>
  );
}
