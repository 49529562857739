import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import ThinFooter from "../Footer/ThinFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ProjectApi from "../utils/ProjectApi";

const SingleChannel = () => {
	const {id} = useParams();
  const [channel, setChannel] = useState();
  const [vdos,setVdos] = useState();
  const user = JSON.parse(localStorage.getItem('user'))
  const fetchChannel = () => {
    let token = user.access_token;
    ProjectApi
      .get("fetch-channel/"+id,{headers:{Authorization: 'Bearer '+token}})
      .then((res) => {
        console.log(res.data);
        setChannel(res.data.channel);
		setVdos(res.data.vdo);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(()=>{
	fetchChannel();
  },[])
  return (
    <>
      <ContentWrapper className="single-channel-page">
        {channel && <SingleChannelHero channel = {channel}/>}
        {channel && <SingleChannelNav channel = {channel}/>}
        <Container fluid>
          {vdos && <ChannelVideos vdo={vdos}/>}
        </Container>
      </ContentWrapper>

      <ThinFooter />
    </>
  );
};

export default SingleChannel;
