import axios from "axios"

const endpoint = "https://admin.twittup.com/api/"

let ProjectApi = axios.create({
    baseURL:endpoint,
    timeout:100000,
})

export default ProjectApi;
