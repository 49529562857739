import { useEffect, useState } from "react";
import ListChannel from "./ListChannel";

const ChannelSidebarList = () => {
  const [channels, setChannels] = useState([]);
  useEffect(() => {
    var chn = JSON.parse(localStorage.getItem("channels"));
    if (chn) {
      setChannels(chn);
    }
  }, []);
  return (
    <>
      <li className="nav-item channel-sidebar-list">
        <h6>SUBSCRIPTIONS</h6>
        <ul>
          {channels.map((channel) => {
            return (
              <ListChannel
                href={"/channel/" + channel.channel_url}
                imgSrc={channel.channel_pic}
                label={channel.channel_name}
              />
            );
          })}

          {/* <ListChannel
						href="/subscriptions"
						imgSrc="/img/s2.png"
						label="Unboxing"
						labelBadge="warning"
						badgeValue="2"
					/>
					<ListChannel
						href="/subscriptions"
						imgSrc="/img/s3.png"
						label="Product / Service"
					/>
					<ListChannel
						href="/subscriptions"
						imgSrc="/img/s4.png"
						label="Gaming"
					/> */}
        </ul>
      </li>
    </>
  );
};

export default ChannelSidebarList;
