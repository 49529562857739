import "./VideoPage.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ThinFooter from "../Footer/ThinFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import VideoCardList from "./VideoCardList";
import SingleVideoRight from "./SingleVideoRight";
import SingleVideoLeft from "./SingleVideoLeft";
import YouTube from "react-youtube";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const VideoPage = () => {
	const {id} = useParams();
	const opts = {
		height: "315",
		width: "100%",
		playerVars: {
		  // https://developers.google.com/youtube/player_parameters
		  autoplay: 0,
		},
	  };
	  function _onReady(event) {
		// access to player in all event handlers via event.target
		// event.target.pauseVideo();
		let duration = event.target.getDuration();
		console.log("duration :", (duration / 60).toFixed(0));
	  }
	return (
		<>
			<ContentWrapper>
				<Container fluid>
					<div className="video-block-right-list section-padding">
						<Row className="mb-4">
							<Col md={8}>
								<div className="single-video">
									{/* {id && <iframe
										title="Youtube Video"
										width="100%"
										height="315"
										src={"https://www.youtube-nocookie.com/embed/"+id+"?rel=0&amp;controls=0&amp;showinfo=0"}
										frameborder="0"
										allow=" encrypted-media"
										allowfullscreen
									></iframe>} */}
									{id && <YouTube videoId={id} opts={opts} onReady={_onReady} />}
								</div>
							</Col>

							<Col md={4}>
								<div className="video-slider-right-list">
									<VideoCardList
										imgSrc="img/v1.png"
										time="3:50"
										videoTitle="Here are many variati of passages of Lorem"
										views="1.8M"
										timeAgo="11 Months"
										videoCategory="Education"
										verified
									/>
									<VideoCardList
										imgSrc="img/v1.png"
										time="3:50"
										videoTitle="Duis aute irure dolor in reprehenderit in."
										views="1.8M"
										timeAgo="11 Months"
										videoCategory="Education"
										active
										verified
									/>
									<VideoCardList
										imgSrc="img/v1.png"
										time="3:50"
										videoTitle="Culpa qui officia deserunt mollit anim"
										views="1.8M"
										timeAgo="11 Months"
										videoCategory="Education"
									/>
									<VideoCardList
										imgSrc="img/v1.png"
										time="3:50"
										videoTitle="Deserunt mollit anim id est laborum."
										views="1.8M"
										timeAgo="11 Months"
										videoCategory="Education"
										verified
									/>
									<VideoCardList
										imgSrc="img/v1.png"
										time="3:50"
										videoTitle="Exercitation ullamco laboris nisi ut."
										views="1.8M"
										timeAgo="11 Months"
										videoCategory="Education"
										verified
									/>
									<VideoCardList
										imgSrc="img/v1.png"
										time="3:50"
										videoTitle="There are many variations of passages of Lorem"
										views="1.8M"
										timeAgo="11 Months"
										videoCategory="Education"
										verified
									/>
								</div>
							</Col>
						</Row>
					</div>

					<div className="video-block section-padding">
						<Row>
							<Col md={8}>
								<SingleVideoLeft />
							</Col>

							<Col md={4}>
								<SingleVideoRight />
							</Col>
						</Row>
					</div>
				</Container>
				<ThinFooter />
			</ContentWrapper>
		</>
	);
};

export default VideoPage;
