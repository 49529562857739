import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import ChannelCard from "../Atomics/ChannelCard/ChannelCard";
import { useEffect, useState } from "react";
import axios from "axios";
import ProjectApi from "../utils/ProjectApi";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";


export default function PopularChannels() {
  const [channels, setChannels] = useState([]);
  useEffect(() => {
    var chn = JSON.parse(localStorage.getItem("channels"));
    if (chn) {
      setChannels(chn);
    }
  }, []);
  return (
    <>
      <div className="video-block section-padding ">
        <Row>
          <Col md={12}>
            <SectionHeader heading="Popular Channels" />
          </Col>

          {channels.map((channel) => {
            return (
              <Col xl={3} sm={6} className="mb-3">
                <ChannelCard
                  imgSrc={channel.channel_pic}
                  views={channel.sub_count}
                  channelName={channel.channel_name}
                  subscriberCount={channel.sub_count}
                  channelId={channel.channel_id}
                  channelHref={channel.channel_url}
                  verified
                  outline
                />
              </Col>
            );
          })}
          {/* <Col xl={3} sm={6} className="mb-3">
						<ChannelCard
							imgSrc="img/s1.png"
							views="1.4M"
							channelName="Channel Name"
							subscriberCount="382,323"
							outline
						/>
					</Col>
					<Col xl={3} sm={6} className="mb-3">
						<ChannelCard
							imgSrc="img/s1.png"
							views="1.4M"
							channelName="Channel Name"
							subscriberCount="382,323"
							outline
						/>
					</Col>
					<Col xl={3} sm={6} className="mb-3">
						<ChannelCard
							imgSrc="img/s1.png"
							views="1.4M"
							channelName="Channel Name"
							subscriberCount="382,323"
							verified
							isSubscribed
							outline
						/>
					</Col>
					<Col xl={3} sm={6} className="mb-3">
						<ChannelCard
							imgSrc="img/s1.png"
							views="1.4M"
							channelName="Channel Name"
							subscriberCount="382,323"
							outline
						/>
					</Col> */}
        </Row>
      </div>
    </>
  );
}
