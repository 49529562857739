import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import ProjectApi from "../utils/ProjectApi";

function Login() {
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const fetchChannels = () => {
    var usr = JSON.parse(localStorage.getItem('user'));
    let token = usr.access_token
    ProjectApi
      .get("fetch-channels",{headers:{"Authorization":'Bearer '+token}})
      .then((res) => {
        // console.log(res.data);
        // setChannels(res.data);
        localStorage.setItem('channels',JSON.stringify(res.data));
        window.location.href = "/";
      })
      .catch((err) => {
        /* return(
          <Redirect to="/auth/login"/>
        ) */

        console.log(err);
        // window.history.pushState("","",'/auth/login')
      });
  };
  function handleGuest()
  {
    const pData = {
      name:'Guest',
      email:'guest@gmail.com'
    }

    ProjectApi.post("register", pData).then((result) => {
      // console.log(result.data);
      localStorage.setItem("user", JSON.stringify(result.data));
      
      // localStorage.setItem('channels',JSON.stringify(result.data.channel))
      fetchChannels()
      
    });
  }
  useEffect(() => {
    // console.log(user)
    /* const pData = {
      name:'balkrishna',
      email:'balkrishna@gmail.com'
    }
    ProjectApi.post("register", pData).then((result) => {
      console.log(result.data);
      localStorage.setItem("user", JSON.stringify(result.data));
      window.location.href = "/";
    }); */
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          console.log(res.data);
          const pData = {
            name: res.data.name,
            email: res.data.email,
          };
          ProjectApi.post("register", pData).then((result) => {
            console.log(result.data);
            localStorage.setItem("user", JSON.stringify(result.data));
            fetchChannels()
            // window.location.href = "/";
          });
        })
        .catch((err) => console.log("err", err));
    }
  }, [user]);

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };
  return (
    <>
      <Col md={5} className=" p-5 bg-white full-height">
        <div className="login-main-left">
          <div className="text-center mb-5 login-main-left-header pt-4">
            <img src="/img/favicon.png" className="img-fluid" alt="LOGO" />
            <h5 className="mt-3 mb-3">Welcome to Twittup</h5>
            <p>
              It is a long established fact that a reader <br /> will be
              distracted by the readable.
            </p>
          </div>

          <div>
            {/* <h2>React Google Login</h2> */}
            <br />
            <br />
            {profile ? (
              <div>
                <img src={profile.picture} alt="user image" />
                <h3>User Logged in</h3>
                <p>Name: {profile.name}</p>
                <p>Email Address: {profile.email}</p>
                <br />
                <br />
                <button onClick={logOut}>Log out</button>
              </div>
            ) : (
              <button className="btn" onClick={() => login()}>
                <img
                  src="/img/google_login.png"
                  className="img-fluid"
                  alt="login-img"
                  onClick={() => login()}
                />
              </button>
            )}
          </div>
          <button type="button" onClick={()=>handleGuest()} className="bg-white border-secondary p-3 ml-2" style={{width:310,borderRadius:40,fontSize:20,color:'black'}}>Continue as guest</button>
          {/* <Form>
          <button onClick={() => login()}>Sign in with Google 🚀 </button>
            <Form.Group>
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" placeholder="Enter mobile number" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <div className="mt-4">
              <Row>
                <Col>
                  <Button variant="outline-primary" size="lg" block>
                    Sign In
                  </Button>
                </Col>
              </Row>
            </div>
          </Form> */}

          {/* <div className="text-center mt-5">
            <p className="light-gray">
              Don’t have an account? <Link to="/auth/register">Sign Up</Link>
            </p>
          </div> */}
        </div>
      </Col>
    </>
  );
}
export default Login;
