import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import VideoCard from "../Atomics/VideoCard/VideoCard";
import ProjectApi from "../utils/ProjectApi";
import { useEffect, useState } from "react";

export default function VideoBlock() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [vdos, setVdos] = useState([]);
  const fetchVideos = () => {
    let token = user.access_token;
    ProjectApi.get("feature-videos", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        // console.log(res.data);
        setVdos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchVideos();
  }, []);
  return (
    <>
      <div className="video-block section-padding ">
        <Row>
          <Col md={12}>
            <SectionHeader heading="Featured Videos" />
          </Col>

          {vdos.map((vdo) => {
            return (
              <Col xl={3} sm={6} className="mb-3">
                <VideoCard
                  imgSrc="img/v4.png"
                  time="3:50"
				  videoTitleHref={vdo.vdo_id}
                  videoTitle={vdo.title}
                  videoCategory={vdo.category}
                  views="1.8M"
                  timeAgo="11 months"
                  verified
                />
              </Col>
            );
          })}

          {/* <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col>

          <Col xl={3} sm={6} className="mb-3">
            <VideoCard
              imgSrc="img/v4.png"
              time="3:50"
              videoTitle="There are many variations of passages of Lorem"
              videoCategory="Education"
              views="1.8M"
              timeAgo="11 months"
              verified
            />
          </Col> */}
        </Row>
      </div>
    </>
  );
}
